import { useTokenStore } from "@/stores/TokenStore";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/folder/Inbox",
  },
  {
    path: "/folder/:id",
    component: () => import("../views/FolderPage.vue"),
  },
  {
    path: "/utilizador/index",
    component: () => import("../views/utilizador/UtilizadorIndex.vue"),
  },
  {
    path: "/utilizador/edit",
    component: () => import("../views/utilizador/UtilizadorEdit.vue"),
  },
  {
    path: "/utilizador/:utilizadorID/edit",
    component: () => import("../views/utilizador/UtilizadorEdit.vue"),
  },
  {
    path: "/cliente/index",
    component: () => import("../views/cliente/ClienteIndex.vue"),
  },
  {
    path: "/cliente/edit",
    component: () => import("../views/cliente/ClienteEdit.vue"),
  },
  {
    path: "/cliente/:clienteID/edit",
    component: () => import("../views/cliente/ClienteEdit.vue"),
  },
  {
    path: "/cliente/:clienteID/encarregado",
    component: () => import("../views/cliente/ClienteEncarregado.vue"),
  },
  {
    path: "/cliente/:clienteID/info",
    component: () => import("../views/cliente/ClienteInfo.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/index",
    component: () => import("../views/relvado/RelvadoIndex.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/edit",
    component: () => import("../views/relvado/RelvadoEdit.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/edit",
    component: () => import("../views/relvado/RelvadoEdit.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/info",
    component: () => import("../views/relvado/RelvadoInfo.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/leitura/analise",
    component: () => import("../views/leitura/LeituraAnalise.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/relatorio/index",
    component: () => import("../views/relatorio/RelatorioCampoIndex.vue"),
  },
  {
    path: "/cliente/:clienteID/relatorio/index",
    component: () => import("../views/relatorio/RelatorioClienteIndex.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/leitura/edit",
    component: () => import("../views/leitura/LeituraEdit.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/leitura/:leituraID/edit",
    component: () => import("../views/leitura/LeituraEdit.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/leitura/:variavelID/index",
    component: () => import("../views/leitura/LeituraIndex.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/anexo/:anexoID/cover",
    component: () => import("../views/anexo/AnexoCover.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/anexo/:anexoID/edit",
    component: () => import("../views/anexo/AnexoEdit.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/anexo/edit",
    component: () => import("../views/anexo/AnexoEdit.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/anexo/index",
    component: () => import("../views/anexo/AnexoIndex.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/utilizacao/edit",
    component: () => import("../views/utilizacao/UtilizacaoEdit.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/utilizacao/:utilizacaoID/edit",
    component: () => import("../views/utilizacao/UtilizacaoEdit.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/utilizacao/index",
    component: () => import("../views/utilizacao/UtilizacaoIndex.vue"),
  },
  {
    path: "/cliente/:clienteID/relvado/:relvadoID/utilizacao/analise",
    component: () => import("../views/utilizacao/UtilizacaoAnalise.vue"),
  },
  {
    path: "/tarefa/index",
    component: () => import("../views/tarefa/TarefaIndex.vue"),
  },
  {
    path: "/tarefa/cliente/:clienteID",
    component: () => import("../views/tarefa/TarefaClienteIndex.vue"),
  },
  {
    path: "/tarefa/relvado/:relvadoID",
    component: () => import("../views/tarefa/TarefaRelvadoIndex.vue"),
  },
  {
    path: "/tarefa/edit",
    component: () => import("../views/tarefa/TarefaEdit.vue"),
  },
  {
    path: "/tarefa/:tarefaID/edit",
    component: () => import("../views/tarefa/TarefaEdit.vue"),
  },
  {
    path: "/tarefamodelo/index",
    component: () => import("../views/tarefamodelo/TarefaModeloIndex.vue"),
  },
  {
    path: "/tarefamodelo/edit",
    component: () => import("../views/tarefamodelo/TarefaModeloEdit.vue"),
  },
  {
    path: "/tarefamodelo/:tarefaModeloID/edit",
    component: () => import("../views/tarefamodelo/TarefaModeloEdit.vue"),
  },
  {
    path: "/login",
    component: () => import("../views/utilizador/LoginPage.vue"),
  },
  {
    path: "/logout",
    component: () => import("../views/utilizador/LogoutPage.vue"),
  },
  {
    path: "/palavrapasse/index",
    component: () => import("../views/utilizador/PalavraPasseEdit.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const tokenStore = useTokenStore();
  if (!tokenStore.token.access_token && to.fullPath != "/login") {
    router.push("/login");
  }
});

export default router;
