import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/theme.css";

import { addIcons } from "ionicons";
import {
  carOutline,
  personOutline,
  settingsOutline,
  starOutline,
  trashOutline,
  calendarOutline,
  clipboardOutline,
  refreshOutline,
  addOutline,
  pencilOutline,
  exitOutline,
  saveOutline,
  informationCircle,
  pieChartOutline,
  analyticsOutline,
  imagesOutline,
  imageOutline,
  bookOutline,
  checkboxOutline,
  peopleOutline,readerOutline
} from "ionicons/icons";

addIcons({
  car: carOutline,
  person: personOutline,
  settings: settingsOutline,
  star: starOutline,
  trash: trashOutline,
  calendar: calendarOutline,
  clipboard: clipboardOutline,
  refresh: refreshOutline,
  add: addOutline,
  edit: pencilOutline,
  exit: exitOutline,
  save: saveOutline,
  info: informationCircle,
  piechart: pieChartOutline,
  analytics: analyticsOutline,
  images: imagesOutline,
  image: imageOutline,
  book: bookOutline,
  checkbox: checkboxOutline,
  people: peopleOutline,
  reader: readerOutline,

});

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonListHeader,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonInput,
  IonAlert,
  IonModal,
  IonButton,
  IonMenuToggle,
  IonMenuButton,
  IonBackButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonIcon,
  IonCheckbox,
  IonText,
  IonPopover,
  IonDatetime,
  IonCol,
  IonRow,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSelect,
  IonSelectOption,
  IonBadge,
  IonNote,
  IonTextarea,
  IonToggle,
  IonDatetimeButton,
  IonThumbnail,
  IonImg,
  IonSpinner,
} from "@ionic/vue";

const app = createApp(App)
  .use(IonicVue, { rippleEffect: false, mode: "ios" })
  .use(router)
  .use(createPinia());

app.component("ion-buttons", IonButtons);
app.component("ion-content", IonContent);
app.component("ion-header", IonHeader);
app.component("ion-page", IonPage);
app.component("ion-title", IonTitle);
app.component("ion-toolbar", IonToolbar);
app.component("ion-list", IonList);
app.component("ion-list-header", IonListHeader);
app.component("ion-item", IonItem);
app.component("ion-item-divider", IonItemDivider);
app.component("ion-item-group", IonItemGroup);
app.component("ion-item-option", IonItemOption);
app.component("ion-item-options", IonItemOptions);
app.component("ion-item-sliding", IonItemSliding);
app.component("ion-label", IonLabel);
app.component("ion-input", IonInput);
app.component("ion-alert", IonAlert);
app.component("ion-modal", IonModal);
app.component("ion-button", IonButton);
app.component("ion-menu-button", IonMenuButton);
app.component("ion-menu-toggle", IonMenuToggle);
app.component("ion-back-button", IonBackButton);
app.component("ion-card", IonCard);
app.component("ion-card-content", IonCardContent);
app.component("ion-card-header", IonCardHeader);
app.component("ion-card-subtitle", IonCardSubtitle);
app.component("ion-card-title", IonCardTitle);
app.component("ion-searchbar", IonSearchbar);
app.component("ion-segment", IonSegment);
app.component("ion-segment-button", IonSegmentButton);
app.component("ion-badge", IonBadge);
app.component("ion-icon", IonIcon);
app.component("ion-checkbox", IonCheckbox);
app.component("ion-text", IonText);
app.component("ion-popover", IonPopover);
app.component("ion-datetime", IonDatetime);
app.component("ion-col", IonCol);
app.component("ion-row", IonRow);
app.component("ion-grid", IonGrid);
app.component("ion-infinite-scroll", IonInfiniteScroll);
app.component("ion-infinite-scroll-content", IonInfiniteScrollContent);
app.component("ion-note", IonNote);
app.component("ion-textarea", IonTextarea);
app.component("ion-select", IonSelect);
app.component("ion-select-option", IonSelectOption);
app.component("ion-toggle", IonToggle);
app.component("ion-datetime-button", IonDatetimeButton);
app.component("ion-thumbnail", IonThumbnail);
app.component("ion-img", IonImg);
app.component("ion-spinner", IonSpinner);
router.isReady().then(() => {
  app.mount("#app");
});
