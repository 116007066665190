import { Token } from '../models/Token';

import { defineStore } from 'pinia'
 

export const useTokenStore = defineStore( {
  id:'tokenId',
  // arrow function recommended for full type inference
  state: () => {
    return {
      token:  new Token()
    }
  },
  getters:{

    isAdministrator:  (state) => {
      return state.token.roles.indexOf("ADMINISTRATOR")>=0;
    },
    isEncarregado:  (state) => {
      return state.token.roles.indexOf("ENCARREGADO")>=0;
    },
  }
})


